/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HotelDetailsV3 } from './HotelDetailsV3';
import {
    HotelDetailsV3FromJSON,
    HotelDetailsV3FromJSONTyped,
    HotelDetailsV3ToJSON,
} from './HotelDetailsV3';

/**
 * 
 * @export
 * @interface HotelDetailsResponseV3
 */
export interface HotelDetailsResponseV3 {
    /**
     * 
     * @type {HotelDetailsV3}
     * @memberof HotelDetailsResponseV3
     */
    hotelDetails: HotelDetailsV3;
}

/**
 * Check if a given object implements the HotelDetailsResponseV3 interface.
 */
export function instanceOfHotelDetailsResponseV3(value: object): value is HotelDetailsResponseV3 {
    if (!('hotelDetails' in value) || value['hotelDetails'] === undefined) return false;
    return true;
}

export function HotelDetailsResponseV3FromJSON(json: any): HotelDetailsResponseV3 {
    return HotelDetailsResponseV3FromJSONTyped(json, false);
}

export function HotelDetailsResponseV3FromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelDetailsResponseV3 {
    if (json == null) {
        return json;
    }
    return {
        
        'hotelDetails': HotelDetailsV3FromJSON(json['hotel_details']),
    };
}

export function HotelDetailsResponseV3ToJSON(value?: HotelDetailsResponseV3 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'hotel_details': HotelDetailsV3ToJSON(value['hotelDetails']),
    };
}

