/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TransferDuration } from './TransferDuration';
import {
    TransferDurationFromJSON,
    TransferDurationFromJSONTyped,
    TransferDurationToJSON,
} from './TransferDuration';

/**
 * 
 * @export
 * @interface TransferPartnerSummary
 */
export interface TransferPartnerSummary {
    /**
     * 
     * @type {string}
     * @memberof TransferPartnerSummary
     */
    partner?: string;
    /**
     * 
     * @type {number}
     * @memberof TransferPartnerSummary
     */
    partnerPoints?: number;
    /**
     * Converted points to hotel points for the current loyalty program
     * @type {number}
     * @memberof TransferPartnerSummary
     */
    convertedPoints?: number;
    /**
     * 
     * @type {TransferDuration}
     * @memberof TransferPartnerSummary
     */
    duration?: TransferDuration;
    /**
     * Average point redemption value in cents/pt. Compare this to the selected rate to determine if it's worth transferring points.
     * @type {number}
     * @memberof TransferPartnerSummary
     */
    averagePointRedemptionValue?: number;
}

/**
 * Check if a given object implements the TransferPartnerSummary interface.
 */
export function instanceOfTransferPartnerSummary(value: object): value is TransferPartnerSummary {
    return true;
}

export function TransferPartnerSummaryFromJSON(json: any): TransferPartnerSummary {
    return TransferPartnerSummaryFromJSONTyped(json, false);
}

export function TransferPartnerSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransferPartnerSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'partner': json['partner'] == null ? undefined : json['partner'],
        'partnerPoints': json['partner_points'] == null ? undefined : json['partner_points'],
        'convertedPoints': json['converted_points'] == null ? undefined : json['converted_points'],
        'duration': json['duration'] == null ? undefined : TransferDurationFromJSON(json['duration']),
        'averagePointRedemptionValue': json['average_point_redemption_value'] == null ? undefined : json['average_point_redemption_value'],
    };
}

export function TransferPartnerSummaryToJSON(value?: TransferPartnerSummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'partner': value['partner'],
        'partner_points': value['partnerPoints'],
        'converted_points': value['convertedPoints'],
        'duration': TransferDurationToJSON(value['duration']),
        'average_point_redemption_value': value['averagePointRedemptionValue'],
    };
}

