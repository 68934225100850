import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import TooltipContent from "./TooltipContent";

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip: React.FC<
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Root>
> = ({
  children,
  ...props
}: React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Root>) => {
  return (
    <TooltipProvider>
      <TooltipPrimitive.Root {...props}>{children}</TooltipPrimitive.Root>
    </TooltipProvider>
  );
};

const TooltipTrigger = TooltipPrimitive.Trigger;

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider };
