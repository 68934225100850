/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PointsSummary } from './PointsSummary';
import {
    PointsSummaryFromJSON,
    PointsSummaryFromJSONTyped,
    PointsSummaryToJSON,
} from './PointsSummary';
import type { RawRateSummary } from './RawRateSummary';
import {
    RawRateSummaryFromJSON,
    RawRateSummaryFromJSONTyped,
    RawRateSummaryToJSON,
} from './RawRateSummary';

/**
 * 
 * @export
 * @interface RatesSummary
 */
export interface RatesSummary {
    /**
     * 
     * @type {RawRateSummary}
     * @memberof RatesSummary
     */
    lowestCashRate?: RawRateSummary;
    /**
     * The score of the deal. A value between 0 and 10, where 10 is a great deal.
     * @type {number}
     * @memberof RatesSummary
     */
    lowestCashRateDealScore?: number;
    /**
     * 
     * @type {PointsSummary}
     * @memberof RatesSummary
     */
    pointsSummary?: PointsSummary;
}

/**
 * Check if a given object implements the RatesSummary interface.
 */
export function instanceOfRatesSummary(value: object): value is RatesSummary {
    return true;
}

export function RatesSummaryFromJSON(json: any): RatesSummary {
    return RatesSummaryFromJSONTyped(json, false);
}

export function RatesSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): RatesSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'lowestCashRate': json['lowest_cash_rate'] == null ? undefined : RawRateSummaryFromJSON(json['lowest_cash_rate']),
        'lowestCashRateDealScore': json['lowest_cash_rate_deal_score'] == null ? undefined : json['lowest_cash_rate_deal_score'],
        'pointsSummary': json['points_summary'] == null ? undefined : PointsSummaryFromJSON(json['points_summary']),
    };
}

export function RatesSummaryToJSON(value?: RatesSummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'lowest_cash_rate': RawRateSummaryToJSON(value['lowestCashRate']),
        'lowest_cash_rate_deal_score': value['lowestCashRateDealScore'],
        'points_summary': PointsSummaryToJSON(value['pointsSummary']),
    };
}

