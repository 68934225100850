/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RoomSummaryV3 } from './RoomSummaryV3';
import {
    RoomSummaryV3FromJSON,
    RoomSummaryV3FromJSONTyped,
    RoomSummaryV3ToJSON,
} from './RoomSummaryV3';
import type { RateResponseStatus } from './RateResponseStatus';
import {
    RateResponseStatusFromJSON,
    RateResponseStatusFromJSONTyped,
    RateResponseStatusToJSON,
} from './RateResponseStatus';
import type { RecommendationSummary } from './RecommendationSummary';
import {
    RecommendationSummaryFromJSON,
    RecommendationSummaryFromJSONTyped,
    RecommendationSummaryToJSON,
} from './RecommendationSummary';
import type { TransferPartnerSummary } from './TransferPartnerSummary';
import {
    TransferPartnerSummaryFromJSON,
    TransferPartnerSummaryFromJSONTyped,
    TransferPartnerSummaryToJSON,
} from './TransferPartnerSummary';
import type { RatesSummary } from './RatesSummary';
import {
    RatesSummaryFromJSON,
    RatesSummaryFromJSONTyped,
    RatesSummaryToJSON,
} from './RatesSummary';

/**
 * Additional Rate Response V3
 * @export
 * @interface AdditionalRateResponseV3
 */
export interface AdditionalRateResponseV3 {
    /**
     * 
     * @type {Array<RoomSummaryV3>}
     * @memberof AdditionalRateResponseV3
     */
    additionalRates: Array<RoomSummaryV3>;
    /**
     * 
     * @type {RateResponseStatus}
     * @memberof AdditionalRateResponseV3
     */
    status: RateResponseStatus;
    /**
     * 
     * @type {RatesSummary}
     * @memberof AdditionalRateResponseV3
     */
    ratesSummary?: RatesSummary;
    /**
     * 
     * @type {Array<TransferPartnerSummary>}
     * @memberof AdditionalRateResponseV3
     */
    transferPartnerSummaries?: Array<TransferPartnerSummary>;
    /**
     * 
     * @type {RecommendationSummary}
     * @memberof AdditionalRateResponseV3
     */
    recommendationSummary?: RecommendationSummary;
}

/**
 * Check if a given object implements the AdditionalRateResponseV3 interface.
 */
export function instanceOfAdditionalRateResponseV3(value: object): value is AdditionalRateResponseV3 {
    if (!('additionalRates' in value) || value['additionalRates'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function AdditionalRateResponseV3FromJSON(json: any): AdditionalRateResponseV3 {
    return AdditionalRateResponseV3FromJSONTyped(json, false);
}

export function AdditionalRateResponseV3FromJSONTyped(json: any, ignoreDiscriminator: boolean): AdditionalRateResponseV3 {
    if (json == null) {
        return json;
    }
    return {
        
        'additionalRates': ((json['additional_rates'] as Array<any>).map(RoomSummaryV3FromJSON)),
        'status': RateResponseStatusFromJSON(json['status']),
        'ratesSummary': json['rates_summary'] == null ? undefined : RatesSummaryFromJSON(json['rates_summary']),
        'transferPartnerSummaries': json['transfer_partner_summaries'] == null ? undefined : ((json['transfer_partner_summaries'] as Array<any>).map(TransferPartnerSummaryFromJSON)),
        'recommendationSummary': json['recommendation_summary'] == null ? undefined : RecommendationSummaryFromJSON(json['recommendation_summary']),
    };
}

export function AdditionalRateResponseV3ToJSON(value?: AdditionalRateResponseV3 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'additional_rates': ((value['additionalRates'] as Array<any>).map(RoomSummaryV3ToJSON)),
        'status': RateResponseStatusToJSON(value['status']),
        'rates_summary': RatesSummaryToJSON(value['ratesSummary']),
        'transfer_partner_summaries': value['transferPartnerSummaries'] == null ? undefined : ((value['transferPartnerSummaries'] as Array<any>).map(TransferPartnerSummaryToJSON)),
        'recommendation_summary': RecommendationSummaryToJSON(value['recommendationSummary']),
    };
}

